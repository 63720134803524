import http from '@/libs/http'
import { useStore } from '@/store'

export default (params) => {
  const store = useStore()
  const url = `${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/exportar_ov_excel/capita`
  const token = store.getters['auth/getToken']
  const object = {
    ...params,
    token
  }
  console.log('token------->', object)
  const res = http.getUri({ url, params: object })
  window.open(res, '_blank')
}
