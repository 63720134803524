<template>
  <section>
    <div class="max-h-screen overflow-y-auto">
      <DataTable :value="listadoOrdenesCapita" @click="checkOrden" v-model:selection="ordenesSeleccionadas" dataKey="DocNumOrden" responsiveLayout="scroll" >
          <Column headerClass="lg:text-xs" selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="DocNumOrden" header="NO. OV"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="Bodega" header="NOM. BODEGA"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="Cliente" header="CLIENTE"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="MotivoAutorizacion" header="MOT. AUTORIZACIÓN"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" header="REGIMEN">
            <template #body="{data}">
              <p class="text-xs">
                {{ data.Regimen ? listadoRegimen.filter(a => a.Regimen == data.Regimen)[0].NombreRegimen : 'Sin Regimen'}}
              </p>
            </template>
          </Column>
          <!-- <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="DptMunicipio" header="ZONA"></Column> -->
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" header="ZONA">
            <template #body="{data}">
              <p v-tooltip.top="`Portabilidad: ${data.DptMunicipioPor}`" class="text-sm">  {{data.DptMunicipio}} </p>
            </template>
          </Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="DocDate" header="FECHA">
            <template #body="{data}">
              <p class="text-sm">
                {{dayjs(data.DocDate).format('YYYY-MM-DD')}}
              </p>
            </template>
          </Column>
          <Column headerClass="lg:text-xs" field="DocTotal" header="VLR. TOTAL">
            <template #body="{data}">
              <p class="text-sm">
                {{$h.formatCurrency(parseInt(data.DocTotal))}}
              </p>
            </template>
          </Column>
          <Column
          header="DETALLE"
          headerClass="lg:text-xs"
          bodyClass="lg:text-xs"
          >
            <template #body="{data}">
              <button @click="openModal(data)">
                <i class="pi pi-eye text-blue-800"></i>
              </button>
            </template>
          </Column>
      </DataTable>
    </div>
    <div class="md:flex text-center justify-center gap-4 items-center">
      <p class="text-gray-600">
        Mostrando de {{totalItemsCount > 1 ? (offset +1) : offset}} a {{totalItemsCount > limit ? (listadoOrdenesCapita.length === limit ? limit + offset : listadoOrdenesCapita.length + offset) : totalItemsCount }} de {{totalItemsCount}}
      </p>
      <Paginator v-model:first="offset"
        :rows="limit"
        :totalRecords="totalItemsCount"
        :rowsPerPageOptions="[2,10,20,30, 100, 500, 2500, 5000, totalItemsCount ]"
        @page="onPage($event)"
      />
    </div>
    <div class="w-full justify-between md:flex items-center mt-4">
      <div>
        <p class="font-bold">Total de ordenes seleccionadas: {{uniqBy(ordenesSeleccionadas, 'DocNumOrden').length}}</p>
      </div>
      <div class="md:flex justify-between w-full lg:w-6/12 items-center">
        <div>
          <p class="font-bold">Total Factura: 0</p>
        </div>
        <div>
          <Button @click="exportarExcel" icon="pi pi-file-excel" label="Exportar" class="p-button-success my-2 lg:my-0" />
        </div>
        <div>
          <Button @click="openModalValidacionOvs" label="Generar facturación" />
        </div>
      </div>
    </div>
    <Dialog v-model:visible="displayModalVerificacionNumeroOvs" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
      <div class="w-full flex justify-center">
        <svg style="width:100px;height:100px" viewBox="0 0 24 24">
            <path fill="orange" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div class="w-full text-center">
        <p class="font-bold text-3xl">Total seleccionados: {{uniqBy(ordenesSeleccionadas, 'DocNumOrden').length}}</p>
        <p class="font-bold text-3xl my-4">Total factura: 0</p>
        <InputNumber inputId="integeronly" v-model="numeroOvSegunUsuario" />
      </div>
        <template #footer>
          <div class="w-full text-center">
            <Button label="Si generar!" icon="pi pi-check" @click="(numeroOvSegunUsuario === uniqBy(ordenesSeleccionadas, 'DocNumOrden').length ? validarOrdenes() : alertar('El numero de ordenes ingresado no coincide con el seleccionado', 1))" autofocus />
            <Button label="No, Cancelar!" icon="pi pi-times" @click="(displayModalVerificacionNumeroOvs = false)" class="bg-gray-600 border-0"/>
          </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '35vw'}" :modal="true">
      <template #header>
        <div class="flex items-center">
          <i class="pi pi-list mr-3"></i>
          <p>
            Entregas de la orden <span class="font-bold">{{dataModalEntregas.DocNumOrden}}</span>
          </p>
        </div>
      </template>
      <div class="w-full p-4">
          <DataTable
              :value="entregas"
              v-model:expandedRows="expandedRows"
              dataKey="DocNum"
              class="p-datatable-sm"
              @rowExpand="onRowExpand"
              responsiveLayout="scroll">
              <Column bodyClass="text-sm" headerClass="text-sm" :expander="true" headerStyle="width: 3rem; background-color: white;" />
              <Column bodyClass="text-sm" headerClass="text-sm" field="DocNum" headerStyle="background-color: white;" header="Entrega"></Column>
              <Column bodyClass="text-sm" headerClass="text-sm" headerStyle="background-color: white;" header="Fecha">
                  <template #body="slotProps">
                    {{slotProps.data.Fecha}}
                  </template>
              </Column>
              <Column bodyClass="text-sm" headerClass="text-sm" headerStyle="background-color: white;" header="Valor">
                  <template #body="slotProps">
                    {{$h.formatCurrency(parseInt(slotProps.data.Valor))}}
                  </template>
              </Column>
              <template #expansion="slotProps">
                  <div class="orders-subtable">
                    <DataTable class="p-datatable-sm" :value="slotProps.data.medicamentos" responsiveLayout="scroll">
                        <Column bodyClass="text-sm" headerClass="text-sm" field="ItemCode" header="Medicamento"></Column>
                        <Column bodyClass="text-sm" headerClass="text-sm" field="Dscription" header="Descripción"></Column>
                        <Column bodyClass="text-sm" headerClass="text-sm" header="Cantidad">
                          <template #body="slotProps">
                            {{parseInt(slotProps.data.Quantity)}}
                          </template>
                        </Column>
                        <Column bodyClass="text-sm" headerClass="text-sm" header="Amount">
                          <template #body="slotProps">
                            {{$h.formatCurrency(parseInt(slotProps.data.LineTotal))}}
                          </template>
                        </Column>
                      </DataTable>
                  </div>
              </template>
          </DataTable>
        </div>
    </Dialog>
    <Dialog header="Ordenes con medicamentos inactivos" v-model:visible="displayModalValidacionMds" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
      <div class="w-full">
        <p class="font-bold"> Las siguientes ordenes contienen medicamentos inactivos: </p>
        <div v-for="(md, o) of ordenesMdsInactivos" :key="o">
          <div class="w-full flex items-center mt-4 font-medium text-red-600">
            <i class="pi pi-info-circle mr-2"></i>
            Orden: {{ md.OV }} - Medicamento: {{ md.ItemCode }}
          </div>
        </div>
        <div class="w-full flex justify-end gap-4">
          <Button label="Cancelar" @click="displayModalValidacionMds = false" class="p-button-outlined p-button-secondary" />
          <Button @click="facturar" label="Excluir ordenes y continuar" />
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
  import OrdenesService from '../../../services/ordenes.service'
  import GenerarFacturasService from '../../../services/generarFacturas.service'
  import facturacionStore from '../../../store/facturacion.store'
  import ExportarExcel from '../../../services/exportar_excel_capita.service'
  import { computed, onMounted, ref, watch } from 'vue'
  import Swal from 'sweetalert2'
  import { uniqBy } from 'lodash'
  import dayjs from 'dayjs'
  export default {
    name: 'facturacionMasivaCapita',
    props: {
      cliente: {
        type: Object,
        default: () => {
          return {}
        }
      },
      dataMenu: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    setup (props) {
      // Services
      const _OrdenesService = ref(new OrdenesService())
      const _GenerarFacturasService = ref(new GenerarFacturasService())
      // References
      const offset = ref(0)
      const page = ref(0)
      const limit = ref(10)
      const totalItemsCount = ref(0)
      const totalSumaOrdenes = ref(0)
      const listadoOrdenesCapita = ref([])
      const ordenesSeleccionadas = ref([])
      const ordenesFacturar = ref([])
      const displayModal = ref(false)
      const expandedRows = ref([])
      const entregas = ref([])
      const dataModalEntregas = ref({})
      const ordenesMdsInactivos = ref([])
      const numeroOvSegunUsuario = ref(0)
      const displayModalValidacionMds = ref(false)
      const displayModalVerificacionNumeroOvs = ref(false)
      const ordenesListasEnviar = ref()
      // Computed
      const filtros = computed(() => facturacionStore.getters._filtros)
      const params = computed(() => {
        return {
          offset: offset.value,
          page: page.value,
          limit: limit.value,
          filters: filtros.value
        }
      })
      const listadoRegimen = computed(() => facturacionStore.getters._listadoRegimen)
      const dataFacturar = computed(() => facturacionStore.getters._dataFacturaCapita)
      // Data
      const listarOrdenesVenta = () => {
        _OrdenesService.value.capita(params.value).then(({ data }) => {
          totalItemsCount.value = data.total
          listadoOrdenesCapita.value = data.rows
        })
      }
      // Methods
      const checkOrden = () => {
        ordenesFacturar.value = []
        for (const entrega of listadoOrdenesCapita.value) {
          if (ordenesSeleccionadas.value.length) {
            for (const orden of ordenesSeleccionadas.value) {
              if (orden.DocNumOrden === entrega.DocNumOrden) {
                if (ordenesFacturar.value.length) {
                  if (orden.DocNumEntrega !== entrega.DocNumEntrega) {
                    ordenesFacturar.value.push({
                      DocNum: entrega.DocNumOrden,
                      DocTotal: entrega.DocTotal,
                      _key: ordenesFacturar.value.length,
                      baseEntrys: entrega.BaseEntry
                    })
                    ordenesSeleccionadas.value.push(entrega)
                  }
                } else {
                  ordenesFacturar.value.push({
                    DocNum: entrega.DocNumOrden,
                    DocTotal: entrega.DocTotal,
                    _key: ordenesFacturar.value.length,
                    baseEntrys: entrega.BaseEntry
                  })
                }
              }
            }
          }
        }
        totalSumaOrdenes.value = 0
        ordenesSeleccionadas.value.map(a => {
          totalSumaOrdenes.value += parseInt(a.DocTotal)
        })
      }
      const exportarExcel = () => {
        return ExportarExcel(params.value)
      }
      const openModal = (info) => {
        _OrdenesService.value.entregas({ ov: info.DocNumOrden }).then(({ data }) => {
          entregas.value = data
          entregas.value.map(a => {
            a.medicamentos = []
          })
          displayModal.value = true
          dataModalEntregas.value = info
        })
      }
      const onRowExpand = (event) => {
        _OrdenesService.value.medicamentosEntrega({ DocEntryEntrega: event.data.DocEntryEntrega }).then(({ data }) => {
          for (const entrega of entregas.value) {
            if (entrega.DocNum === event.data.DocNum) {
              entrega.medicamentos = data
            }
          }
        })
      }
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        // listarOrdenesVenta(params.value)
      }
      const alertar = (mensaje, tipo) => {
          const icon = tipo === 1 ? 'warning' : 'warning'
          Swal.fire({
            icon: icon,
            title: ` <span style="line-height: normal;">${mensaje}</span>`
          })
        }
      const openModalValidacionOvs = () => {
          if (Object.values(props.dataMenu).some(a => !a)) {
            alertar('Ingrese todos los datos de la factura')
            facturacionStore.commit('setAbrirMenuCapita', true)
          } else {
            uniqBy(ordenesSeleccionadas.value, 'DocNumOrden').length ? displayModalVerificacionNumeroOvs.value = true : alertar('Debe seleccionar una orden', 1)
          }
      }
      const validarOrdenes = () => {
        displayModalVerificacionNumeroOvs.value = false
        const array = []
        ordenesSeleccionadas.value.map(a => {
          array.push(a.DocNumOrden)
        })
        _GenerarFacturasService.value.validarMdsCapita(array).then(({ data }) => {
          ordenesMdsInactivos.value = data
          facturacionValidada(array)
        })
      }
      const facturacionValidada = (array) => {
        ordenesListasEnviar.value = array
        if (ordenesMdsInactivos.value.length) {
          displayModalValidacionMds.value = true
        } else {
          if (Object.values(dataFacturar.value).some(a => !a)) {
            alertar('Por favor ingrese todos los datos de la factura')
            facturacionStore.commit('setAbrirMenuCapita', true)
          } else {
            const arrayEnv = []
            ordenesSeleccionadas.value.map(a => {
              arrayEnv.push(a)
            })
            const objeto = {
              versionIntranet: filtros.value.versionIntranet,
              ordenes: arrayEnv,
              MesCapita: dataFacturar.value.MesCapita,
              MunicipioF: dataFacturar.value.localizacion.Municipio.substr(0, 14),
              DptoAfiliacion: dataFacturar.value.localizacion.Departamento,
              U_PHR_CodMunicipio: dataFacturar.value.localizacion.U_PHR_CodMunicipio,
              U_PHR_RegimenF: dataFacturar.value.U_PHR_RegimenF, // Cambiar de numeros a letras
              U_PHR_RegPac: dataFacturar.value.U_PHR_RegimenF,
              comentario_factura: dataFacturar.value.comentario_factura,
              habilitar_comentario: 2,
              dias_cotizados: false,
              Desc1: dataFacturar.value.comentario_factura,
              Cliente: props.cliente
            }
            // console.log('---enviando a facturar----', objeto)
            _GenerarFacturasService.value.capita(objeto).then(({ data }) => {
              if (data.error) {
                Swal.fire(
                  'Error',
                  'A ocurrido un error al facturar',
                  'error'
                ).then(() => {
                  listarOrdenesVenta()
                })
              } else {
                Swal.fire(
                  'Guardado',
                  'Las ordenes se estan procesando, por favor revise su historial para ver el seguimiento',
                  'success'
                ).then(() => {
                  listarOrdenesVenta()
                  ordenesSeleccionadas.value = []
                  totalSumaOrdenes.value = 0
                  numeroOvSegunUsuario.value = 0
                })
              }
            })
          }
        }
      }
      const facturar = () => {
        const array = []
        array.push(...ordenesSeleccionadas.value)
        const ordernesExcluir = []
        for (const i of ordenesMdsInactivos.value) {
          ordernesExcluir.push(i.OV)
        }
        for (const i of ordenesSeleccionadas.value) {
          if (ordernesExcluir.includes(i.DocNumOrden)) {
            const index = array.findIndex(a => a.DocNumOrden === i.DocNumOrden)
            array.splice(index, 1)
          }
        }
        if (array.length) {
          if (Object.values(dataFacturar.value).some(a => !a)) {
            alertar('Por favor ingrese todos los datos de la factura')
            facturacionStore.commit('setAbrirMenuCapita', true)
            displayModalVerificacionNumeroOvs.value = false
          } else {
            const objeto = {
              ordenes: array,
              MesCapita: dataFacturar.value.MesCapita,
              MunicipioF: dataFacturar.value.localizacion.Municipio.substr(0, 14),
              DptoAfiliacion: dataFacturar.value.localizacion.Departamento,
              U_PHR_CodMunicipio: dataFacturar.value.localizacion.U_PHR_CodMunicipio,
              U_PHR_RegimenF: dataFacturar.value.U_PHR_RegimenF, // Cambiar de numeros a letras
              U_PHR_RegPac: dataFacturar.value.U_PHR_RegimenF,
              comentario_factura: dataFacturar.value.comentario_factura,
              habilitar_comentario: 2,
              dias_cotizados: false,
              Desc1: dataFacturar.value.comentario_factura,
              Cliente: props.cliente
            }
            _GenerarFacturasService.value.capita(objeto).then(({ data }) => {
              if (data.error) {
                Swal.fire(
                  'Error',
                  'A ocurrido un error al facturar',
                  'error'
                ).then(() => {
                  listarOrdenesVenta()
                  displayModalVerificacionNumeroOvs.value = false
                })
              } else {
                Swal.fire(
                  'Guardado',
                  'Las ordenes se estan procesando, por favor revise su historial para ver el seguimiento',
                  'success'
                ).then(() => {
                  listarOrdenesVenta()
                  displayModalVerificacionNumeroOvs.value = false
                  ordenesSeleccionadas.value = []
                  totalSumaOrdenes.value = 0
                  numeroOvSegunUsuario.value = 0
                  displayModalValidacionMds.value = false
                  // probando si se arregla al vaciar array
                  listadoOrdenesCapita.value = []
                  ordenesFacturar.value = []
                  ordenesMdsInactivos.value = []
                })
              }
            })
          }
        } else {
          Swal.fire(
            'Advertencia',
            'No tienes ordenes por enviar, ya que la cantidad excluida es igual a la cantidad enviada',
            'warning'
          ).then(() => {
            displayModalValidacionMds.value = false
          })
        }
      }
      const valueRegimen = (val) => {
        switch (val) {
          case '01':
            return 'S'
          case '02':
            return 'C'
          case '03':
            return 'MS'
          case '04':
            return 'MC'
          default:
            break
        }
      }
      watch(filtros, (n, v) => {
        if (n) {
          if (!n.limpiar) {
            n.CodCliente.length ? listarOrdenesVenta() : alertar('Debe seleccionar un cliente', 1)
          } else {
            // listarOrdenesVenta()
          }
        }
      })
      onMounted(() => {
        // listarOrdenesVenta()
      })
      return {
        filtros,
        offset,
        limit,
        page,
        listadoOrdenesCapita,
        ordenesSeleccionadas,
        totalItemsCount,
        dayjs,
        displayModal,
        openModal,
        onPage,
        checkOrden,
        ordenesFacturar,
        listadoRegimen,
        totalSumaOrdenes,
        exportarExcel,
        dataFacturar,
        facturar,
        validarOrdenes,
        displayModalVerificacionNumeroOvs,
        alertar,
        numeroOvSegunUsuario,
        dataModalEntregas,
        entregas,
        expandedRows,
        onRowExpand,
        valueRegimen,
        openModalValidacionOvs,
        uniqBy,
        displayModalValidacionMds,
        ordenesMdsInactivos
      }
    }

  }
</script>
<style scoped>
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
:global(.swal2-container) {z-index: 1000000 !important;}
</style>
