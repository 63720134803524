import http from '@/libs/http'
export default class GenerarFacturasService {
  evento (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturar-masivo-evento`, payload, { headers: { loading: true } })
  }

  eventoCapita (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturar-masivo-evento-capita`, payload, { headers: { loading: true } })
  }

  capita (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturar-masivo-capita`, payload, { headers: { loading: true } })
  }

  valor (payload) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturar-valor`, payload, { headers: { loading: true } })
  }

  validarMds (params) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturas/validar-mds-inactivos`, params, { headers: { loading: true } })
  }

  validarMdsCapita (params) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturas/validar-mds-inactivos-capita`, params, { headers: { loading: true } })
  }

  actualizarCantidadOrdenesPorJson (params) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/facturas/actualizar-cantidad-ordenes-json`, params, { headers: { loading: true } })
  }
}
